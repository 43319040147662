import React from 'react';
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import ReactMarkdown from 'react-markdown'

import Layout from "../components/layout"
import SEO from "../components/seo"
import CodeBlock from "../components/CodeBlock"
import RelatedPosts from "../components/relatedPosts"
import {FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, TwitterShareButton, TwitterIcon} from "react-share";

const BlogPost = (props) => (
	<>
		<Helmet
			bodyAttributes={{
				class: "blog-post"
			}}
		/>
		<Layout location={props.location.pathname}
				bannerHeading={props.pageContext.post.title}
				bannerUrl={props.pageContext.post.image.url}>
			<SEO title={props.pageContext.post.title} />
			<div className="container">
				<div className="blog-post-wrap">
					<article className="blog-renderer">
						<header className="blog-post-header">
							<h2>{props.pageContext.post.subtitle}</h2>
							<p>By {props.pageContext.post.author.split('_').join(' ')} {" "}
								{props.pageContext.post.date.substring(0, props.pageContext.post.date.indexOf('T'))}
							</p>
							<div className="share-buttons-wrap">
								<FacebookShareButton url={props.location.href}>
									<FacebookIcon size={32} round={true}/>
								</FacebookShareButton>
								<LinkedinShareButton url={props.location.href}>
									<LinkedinIcon size={32} round={true}/>
								</LinkedinShareButton>
								<TwitterShareButton url={props.location.href}>
									<TwitterIcon size={32} round={true}/>
								</TwitterShareButton>
							</div>
						</header>

						<ReactMarkdown source={props.pageContext.post.body} renderers={{ code: CodeBlock }} className="css-renderer" />
					</article>
				</div>
			</div>
			<RelatedPosts relatedPosts={props.pageContext.post.releatedPosts} />
		</Layout>
	</>
)

BlogPost.propTypes = {
	location: PropTypes.object,
	pageContext: PropTypes.object.isRequired
}

export default BlogPost

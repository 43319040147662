import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import GraphImg from "graphcms-image"

const RelatedPosts = (props) => {
	return (
		<>
			{props.relatedPosts.length !== 0 ?
				<div className="related-posts-section">
					<div className="container">
						<h3 className="related-posts-title">Related Posts</h3>
						<div className="related-posts-list">
							{props.relatedPosts.map(item => (
								<Link
									key={item.id}
									to={`/blog/${item.url.replace(/\s+/g, '-')}/`}
									className="related-post-item">
									<GraphImg image={item.image} fit="scale" backgroundColor={true} withWebp={true} alt={item.fileName} />
									<div className="related-post-item__body">
										<h3>{item.title}</h3>
										<p>
											<span>{item.date.substring(0, item.date.indexOf('T'))}</span>
										</p>
									</div>
								</Link>
							))}
						</div>
					</div>
				</div>
				:
				""
			}
		</>
	)
}

RelatedPosts.propTypes = {
	relatedPosts: PropTypes.array
}

export default RelatedPosts

import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { atomDark } from  'react-syntax-highlighter/dist/esm/styles/prism'

class CodeBlock extends PureComponent {
	static propTypes = {
		value: PropTypes.string.isRequired,
		language: PropTypes.string
	};

	static defaultProps = {
		language: null
	};

	render() {
		const { language, value } = this.props;

		return (
			<SyntaxHighlighter style={atomDark} language={language} showLineNumbers={true} useInlineStyles={false}>
				{value}
			</SyntaxHighlighter>
		);
	}
}

export default CodeBlock;
